<template>
  <main v-if="!hasToken">
    <div class="headline">Verify Your Email Address</div>
    <p class="mt-5">
      Please click on the link that has sent to your email account to verify
      your email and continue the registration process.
    </p>

    <v-divider></v-divider>
    <v-container>
      <span>Verified your email? </span>
      <router-link to="/auth/login">Login</router-link>
    </v-container>
  </main>

  <main v-else>
    <div class="headline">Welcome to ShairMail</div>
    <p class="mt-5">
      Hi {{ email }}, Fill up the following details to Create your account.
    </p>

    <v-form method="post" class="auth-form" v-model="valid" ref="createUser">
      <v-text-field
        class="pt-5"
        label="First Name"
        v-model="firstName"
        :rules="nameRules"
        required
        name="firstName"
      >
      </v-text-field>
      <v-text-field
        class="pt-5"
        label="Last Name"
        v-model="lastName"
        :rules="nameRules"
        required
        name="lastName"
      >
      </v-text-field>

      <v-layout class="mt-5" align-center justify-space-between>
        <v-btn
          width="100%"
          @click="showMnemonic"
          :class="{
            'teal accent-4 white--text': valid,
            disabled: !valid,
          }"
          >Next
        </v-btn>
      </v-layout>
    </v-form>

    <v-expand-transition>
      <v-card
        v-if="revealPassword"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-text class="pb-0">
          <p class="display-1 mb-10 text--primary mt-5">
            <small>Select Password</small>
          </p>

          <v-textarea
            outlined
            readonly
            name="input-7-4 menmonic-text"
            label="Password (seed words)"
            v-model="mnemonic"
            @focus="$event.target.select()"
            rows="3"
          >
          </v-textarea>

          <p>
            use the above phrase as password, COPY and STORE this somewhere safe
            and secret
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 justify-end mx-3">
          <v-btn text color="teal accent-4" @click="generateMnemonic()">
            Generate New
          </v-btn>
          <v-btn color="teal white--text accent-4" @click="submit()">
            Create Account
          </v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text>
          <span>
            <p>
              <b>Note: </b>
              Once password is lost you won't be able to retrive it back.
            </p>
          </span>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </main>
</template>

<script>
// import axios from "@/plugins/axios.js";

export default {
  data() {
    return {
      isTokenVerified: false,
      valid: false,
      hasToken: false,

      token: "",
      email: "",
      mnemonic: "",
      firstName: "",
      lastName: "",
      revealPassword: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
          "You have entered a invalid name",
      ],
    };
  },
  async created() {
    if (this.$route.query.token) {
      this.hasToken = true;
      this.token = this.$route.query.token;

      this.$store
        .dispatch("VERIFY_TOKEN", this.token)
        .then((data) => {
          this.email = data.email;
          this.isTokenVerified = true;
          this.mnemonic = this.$ledgermail_v2.generateMnemonic();
        })
        .catch((message) => {
          this.hasToken = false;
          this.$store.dispatch("ALERT_ERROR", message);
          this.$router.push("/");
        });
    }
  },

  methods: {
    showMnemonic() {
      if (this.$refs.createUser.validate()) this.revealPassword = true;
    },

    generateMnemonic() {
      this.mnemonic = this.$ledgermail_v2.generateMnemonic();
    },

    async submit() {
      this.$store
        .dispatch("CREATE_USER", {
          firstName: this.firstName,
          lastName: this.lastName,
          emailId: this.email,
          token: this.token,
          mnemonic: this.mnemonic,
        })
        .then(() => {
          this.$store.dispatch("ALERT_SUCCESS", "User Created");
          this.$router.push("/auth/login");
        })
        .catch((message) => {
          this.$store.dispatch("ALERT_ERROR", message);
          this.revealPassword = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  left: 0;
}

.menmonic-text {
  background: white;
  width: calc(100% - 20px);
  resize: none;
  border-radius: 5px;
  padding: 10px;
  line-height: 1.4;
}
</style>